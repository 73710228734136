<template>
  <v-container
    fluid
    class="pa-0">
    <v-row>
      <v-col
        sm="3"
        cols="12">
        <v-list
          class="elevation-3"
          style="overflow-y: auto; max-height: calc(100vh - 80px); border-radius: 0.625rem;">
          <v-list-item-group>
            <list-menu
              v-for="(menu, index) in menus"
              :key="`${menu.title}-${index}`"
              :title="menu.title"
              :to="menu.to"
              :icon="menu.icon"
              path="menu.setting"
              icon-prepend />
            <div
              v-if="isShowSuperManagement"
              class="px-4 pt-3 pb-1">
              <a
                :href="superManagement"
                target="_blank">Go to Supermanagement</a>
            </div>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col
        sm="9"
        cols="12">
        <router-view class="pa-sm-3 setting-view" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import listMenu from '@/components/menu/ListMenu.vue'
import { getMenuByRole, getRole } from '@/assets/js/Authentication'
import settingMenu from '@/assets/js/menu/SettingMenus'

export default {
  components: {
    listMenu
  },
  computed: {
    isShowSuperManagement  () {
      const role = getRole()
      const roles = [
        'management',
        'developer',
        'support'
      ]
      return roles.some((r) => r === role)
    },
    menus () {
      return getMenuByRole(settingMenu, this.$router)
    },
    superManagement () {
      return process.env.VUE_APP_SUPER_MANAGEMENT || 'https://staging-camp-supermanagement.netlify.app'
    }
  }
}
</script>

<style scoped>

</style>
